import React, { useEffect, useState, memo } from "react"
import styled from "styled-components"
import Select from "react-select"
import Counter from "./Counter"
import CompareLocation from "../Products/CompareLocation"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Filter = memo(
  ({
    title = "",
    selectorTag = "main",
    optional = false,
    defaultIndex,
    setSelected = () => {},
    options,
    quantity,
    onQuantityChange = () => {},
    onCounterFocusChange = () => {},
    counterFocus = false,
    maxStock,
    includeCounter = true,
    includeSelector = true,
    quantityOnly = false,
    search,
    location,
    disableDropdown,
  }) => {
    const [disabled, setDisabled] = useState(optional)
    const [selectedOption, setSelectedOption] = useState(
      options && options[defaultIndex]
    )
    const { size } = search

    const isBrowser = () => typeof window !== "undefined"

    const setUrlQuerySize = (search_params, url, sizeQuery) => {
      search_params.set("size", sizeQuery)
      url.search = search_params.toString()
    }

    const addSizeQuery = (size) => {
      if (isBrowser()) {
        var url = new URL(window.location.href)
        var search_params = new URLSearchParams(url.search)
        const newQuery = size
        setUrlQuerySize(search_params, url, newQuery)
      }
    }

    // setselected to queryparam
    useEffect(() => {
      const sizeString = deslugSize(size)
      options.forEach((opt, i) => {
        if (opt.value == sizeString) {
          setSelectedOption(options && options[i])
          addSizeQuery(sizeString)
        }
      })
    }, [])

    // This forces a re-selection of option 0 when we change the options array
    useEffect(() => {
      if (options && !options.some((op) => op.value === selectedOption.value)) {
        setSelectedOption(options && options[0])
      }
    }, [options])

    // Add/Remove item from "Precart"
    useEffect(() => {
      setSelected((prev) => {
        let newState = Object.assign({}, prev)
        if (disabled) {
          delete newState[selectorTag]
        } else {
          newState[selectorTag] = selectedOption
        }
        return newState
      })
    }, [disabled, selectedOption])

    // reset quantity to 1 on selection change
    useEffect(() => {
      onQuantityChange(1)
    }, [selectedOption])

    // Set new selection and run callback
    const optionChangeHandler = (newOption) => {
      setSelectedOption(newOption)
      addSizeQuery(newOption.value)
    }

    const QuantitySelector = () => {
      return (
        <StackedRow quantityOnly={quantityOnly}>
          <div>
            <Label center bold>
              Quantity
            </Label>
          </div>
          <Counter
            value={quantity}
            onChange={onQuantityChange}
            onFocusChange={onCounterFocusChange}
            focus={counterFocus}
            disabled={disableDropdown || maxStock == 0}
            min={1}
            max={maxStock}
          />
        </StackedRow>
      )
    }

    if (quantityOnly) {
      return (
        <MiniContainer>
          <QuantitySelector />
        </MiniContainer>
      )
    } else {
      return (
        <Container
          includeCounter={includeCounter}
          includeSelector={includeSelector}
        >
          {includeSelector && (
            <StackedRow>
              <div>
                <Label bold>
                  {optional && (
                    <input
                      type="checkbox"
                      value={!disabled}
                      onChange={() =>
                        setDisabled((prevCheckbox) => !prevCheckbox)
                      }
                    />
                  )}
                  {title}
                </Label>
              </div>
              <Select
                styles={{
                  control: (styles) => ({
                    ...styles,
                    cursor: "pointer",
                    height: "100%",
                    backgroundColor: "#F8F8F8",
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    width: "94%",
                  }),
                  indicatorsContainer: (styles) => ({
                    ...styles,
                    svg: {
                      color: "#333333",
                    },
                  }),
                }}
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={optionChangeHandler}
                isDisabled={disabled || disableDropdown}
                isSearchable={false}
                required
                // isLoading={isLoading}
                name="sizes"
                options={options}
              />
            </StackedRow>
          )}
          {includeCounter && <QuantitySelector />}
        </Container>
      )
    }
  }
)

const deslugSize = (size) => {
  if (size != undefined) {
    return size
      .replace(/-/g, " ")
      .replace("xl", "XL")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  }
}

// ======================
// 	      STYLES
// ======================

const SPACE = "10px"

//grid-template-columns changed to make selectors and counters fit better when one or the other is disabled
const Container = styled.div`
  background: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  padding: ${SPACE};
  border-radius: 3px;
  display: grid;
  grid-template-columns: ${(props) => (props.includeSelector ? "1fr" : "100px")} ${(
      props
    ) =>
      props.includeCounter
        ? props.includeSelector
          ? "100px"
          : "1fr"
        : "-10px"};
  grid-gap: ${SPACE};
`

const MiniContainer = styled.div`
  background: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  padding: ${SPACE};
  border-radius: 3px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: ${SPACE} 0px;
  font-size: 16px;
  width: fit-content;

  input {
    margin-right: ${SPACE};
  }

  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ center }) => center && "justify-content:center;"}
	${({ center }) => center && "margin: 0 auto;"}
`

const StackedRow = styled.div`
  display: ${({ quantityOnly }) => (quantityOnly ? "flex" : "grid")};
  ${({ quantityOnly }) =>
    quantityOnly ? "justify-content: center;" : "grid-template-rows: 1fr 1fr;"}
  div {
    ${({ quantityOnly }) => quantityOnly && "margin: 0px 10px;"}
  }
`

export default CompareLocation(Filter)
