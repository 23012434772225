/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import ProductSingle from "../components/ProductSingle"
import { path } from "../lib/util"

const ProductPage = (props) => {
  const { pageContext, location } = props
  const thisProduct = props.data.wcProducts
  thisProduct.instalmentMap = pageContext.fevertree_instalment_map
  const imagePlaceholder = props.data.imagePlaceholder
  const brandVideo = props.data.brandVideo
  const comfortLevels = pageContext.comfortLevels
  const seo = pageContext.seo
  const post_id = thisProduct.wordpress_id
  const bedWidths =
    (props.data.bedSizeTable && props.data.bedSizeTable.bed_sizes_acf.beds) ||
    []
  const selectedSize = path(["location", "state", "selectedSize"], props)

  // Skip tracking because we perform tracking manually
  return (
    <Layout location={location} product={thisProduct} skipReSciTracking>
      <SEO
        title={thisProduct.name}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <ProductSingle
        location={location}
        selectedSize={selectedSize}
        product={thisProduct}
        comfortLevels={comfortLevels}
        imagePlaceholder={imagePlaceholder}
        brandVideo={brandVideo}
        bedWidths={bedWidths}
      />
    </Layout>
  )
}

export default ProductPage
export const PLACEHOLDER = graphql`
  query($id: Int!) {
    imagePlaceholder: file(
      relativePath: { eq: "placeholders/placeholderBed.png" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
          src
        }
      }
    }
    bedSizeTable: localWpGraphQlPages(slug: { eq: "bed-sizes" }) {
      bed_sizes_acf {
        beds {
          name
          width
        }
      }
    }
    brandVideo: localWpGraphQlTmwGeneralSettings {
      product_videos {
        brandVideo {
          title
          videoUrl
          productCat
          description
          backgoundImage {
            imageFile {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
    wcProducts(wordpress_id: { eq: $id }) {
      wordpress_id
      name
      sku
      slug
      backorders_allowed
      price
      regular_price
      description
      average_rating
      type
      shipping_class
      categories {
        slug
        name
        wordpress_parent_id
        wordpress_id
      }
      attributes {
        name
        options
      }
      acf {
        warrantee
        guarantee
        weight_capacity
        video
        comfort_trial_available
        bed_bundle_size_images {
          bundle_images {
            product_image
            product_image_sku
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
        selected_bases {
          wordpress_id
          name
          images {
            src
            localFile {
              url
              childImageSharp {
                fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          product_variations {
            id
            sku
            regular_price
            price
            image {
              src
              localFile {
                url
                childImageSharp {
                  fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            attributes {
              name
              option
            }
          }
        }
      }
      dimensions {
        height
        length
        width
      }
      images {
        src
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      bundled_by {
        name
        slug
      }
      bundled_items {
        name
        wordpress_id
        sku
        slug
        shipping_class
        backorders_allowed
        categories {
          slug
        }
        product_variations {
          attributes {
            name
            option
          }
          shipping_class
          sku
          price
          regular_price
          backorders_allowed
          id
        }
        price
        regular_price
      }
      product_variations {
        id
        sku
        price
        regular_price
        manage_stock
        stock_status
        stock_quantity
        backorders_allowed
        shipping_class
        attributes {
          name
          option
        }
        dimensions {
          length
          width
          height
        }
        image {
          src
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 500, srcSetBreakpoints: [200]) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      related_products {
        ...ProductInformation
      }
    }
  }
`
