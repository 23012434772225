import React, { memo } from "react"
import styled from "styled-components"
import ProductList from "../Products/ProductList"
import Inner from "../../zzz/layout/pageInner/Inner"
import { path } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const ProductsYouMayLike = memo(
  ({ productName = "product", products = [], selected = {} }) => {
    if (!Array.isArray(products) || !products.length) {
      return null
    }
    return (
      <Container>
        <Inner>
          <Heading>
            <h3>You might also like</h3>
            <p>
              If you liked the {productName}, you will love these options as
              well...
            </p>
          </Heading>
          <ProductList
            productList={products}
            productsPerLoad={4}
            clearFilterIfEmpty
            sizesFilter={
              path(["attributes", 0, "option"], selected.main)
                ? [path(["attributes", 0, "option"], selected.main)]
                : []
            }
          />
        </Inner>
      </Container>
    )
  }
)
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 60px;
  text-align: center;
  h3 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;
    border-bottom: 3px solid #ed1c24;
  }
`

const Container = styled.div`
  padding: 60px 0;
  background: white;
`

export default ProductsYouMayLike
