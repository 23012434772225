import React, { memo } from "react"
import styled from "styled-components"
import { Container } from "./Layout"
import Attributes from "./Attributes"
import AccordianImplementation from "./AccordianImplementation"
import StarRatings from "react-star-ratings"
import ReactPlayer from "react-player/youtube"
import { path } from "../../lib/util"
import ComfortLevel from "./ComfortLevel"
import Review from "./Review/Review"
import Table from "./Table"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductDetails = memo(
  ({
    location,
    product,
    productStatus,
    productRating,
    comfortLevels,
    selected,
    goToRatingsValue,
    setGoToRatingsValue,
    sizeWidths,
  }) => {
    let comfortLevel = product.attributes.find(
      ({ name }) => name === "Comfort Level"
    )
    const warrantee = product.acf.warrantee
    const guarantee = product.acf.guarantee
    const weight = product.acf.weight_capacity

    comfortLevel =
      comfortLevel && comfortLevel.options
        ? comfortLevel.options[0].toLowerCase()
        : ""

    const items = []
    if (product.description.length) {
      items.push({
        title: "Product Description",
        defaultOpen: true,
        content: product.description,
      })
    }

    if (comfortLevels[comfortLevel]) {
      items.push({
        title: "Comfort Level",
        defaultOpen: true,
        component: (
          <ComfortLevel
            level={comfortLevel}
            body={comfortLevels[comfortLevel]}
          />
        ),
      })
    }

    if (Object.keys(sizeWidths).length !== 0) {
      items.push({
        title:
          product.bundled_items && product.bundled_items.length
            ? "Bed Sizes"
            : "Mattress Sizes",
        defaultOpen: true,
        component: (
          <Table
            tableHeadings={["Size", "Dimensions"]}
            tableRows={sizeWidths}
          />
        ),
      })
    }

    if (product.acf.video) {
      items.push({
        title: "Product video",
        defaultOpen: true,
        component: (
          <ReactPlayer
            width="100%"
            height="450px"
            style={{ margin: "0 auto" }}
            url={product.acf.video}
          />
        ),
      })
    }

    items.push({
      title: "Reviews",
      defaultOpen: false,
      component: (
        <Review
          reviewData={productStatus}
          product={product}
          goToRatingsValue={goToRatingsValue}
        />
      ),
    })

    const length = path(["dimensions", "length"], selected)
    const width = path(["dimensions", "width"], selected)
    const height = path(["dimensions", "height"], selected)

    return (
      <Container grey grid="1fr 3fr" gap="40px" gridMd="1fr">
        <Left>
          <Label>About</Label>
          <Title>{product.name}</Title>
          <Ratings>
            <StarRatings
              rating={productRating}
              changeRating={(newRating) => setGoToRatingsValue(newRating)}
              numberOfStars={5}
              name="rating"
              starDimension="25px"
              starRatedColor="#F7A416"
              starEmptyColor="#CFD5DB"
              starHoverColor="#ffba42"
            />
          </Ratings>
          <Attributes
            location={location}
            length={length}
            width={width}
            height={height}
            comfortLevel={comfortLevel}
            warrantee={warrantee}
            guarantee={guarantee}
            weight={weight}
          />
        </Left>
        <Right>
          <Segment>
            <AccordianImplementation items={items} />
          </Segment>
        </Right>
      </Container>
    )
  }
)

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Segment = styled.div`
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 4px;
  overflow: hidden;
`

const Ratings = styled.div`
  cursor: pointer;
  width: fit-content;
`
const Left = styled.div`
  display: flex;
  flex-direction: column;
`
const Label = styled.div`
  font-size: 14px;
  color: white;
  border-radius: 3px;
  padding: 5px 12px;
  background-color: #223247;
  width: fit-content;
  text-transform: uppercase;
`

const Title = styled.h4`
  color: #223247;
  font-size: 28px;
  font-weight: 400;
  margin: 10px 0;
`
const Right = styled.div``

export default ProductDetails
