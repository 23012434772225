import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
  memo,
} from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { requestStock } from "../../lib/woocommApi"
import ProductVariationSelector from "./ProductVariationSelector"
import InfoIcons from "./InfoIcons"
import ProductDetails from "./ProductDetails"
import VideoRow from "./VideoRow"
import ProductsYouMayLike from "./ProductsYouMayLike"
import { pixelSelect } from "../../services/FacebookPixel"
import { sendRetentionScience } from "../../services/RetentionScience"
import { gtagSelect } from "../../services/GoogleAnalytics"
import { replaceAll, slugify } from "voca"
import { sizeOrder } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductSingle = memo(
  ({
    location,
    product,
    comfortLevels,
    selectedSize,
    imagePlaceholder,
    brandVideo,
    bedWidths = [],
  }) => {
    const [goToRatingsValue, setGoToRatingsValue] = useState(false)
    const [productStock, setProductStock] = useState({})

    // Get graphQL query
    const queryResponse = useStaticQuery(QUERY)

    useEffect(() => {
      if (goToRatingsValue) {
        setGoToRatingsValue(false)
      }
    }, [goToRatingsValue])

    const performStockFetch = () => {
      if (Array.isArray(selected.main.id)) {
        const stockQueries = []
        // Parent product and both bundle ids
        stockQueries.push(requestStock(product.wordpress_id))
        stockQueries.push(requestStock(product.bundled_items[0].wordpress_id))
        stockQueries.push(requestStock(product.bundled_items[1].wordpress_id))

        // Wait for all queries to resolve and then assign stock values
        Promise.all(stockQueries)
          .then((values) => {
            // set stock for all products, but only reviews/rating for parent
            setProductStock({
              ...values[0],
              ...values[1],
              ...values[2],
              review_average_rating: values[0].review_average_rating,
              reviews: [...values[0].reviews],
            })
          })
          .catch((e) => {
            console.log("Stock request failed. Retrying...")
            setTimeout(performStockFetch, 1000)
          })
      } else {
        // Only query single product
        requestStock(product.wordpress_id)
          .then((value) => {
            setProductStock(value)
          })
          .catch((e) => {
            console.log("Stock request failed. Retrying...")
            setTimeout(performStockFetch, 1000)
          })
      }
    }
    useEffect(() => {
      performStockFetch()
    }, [])

    let variationMap = product.product_variations
    // When dealing with bundled items, we have to match up the size of the mattress in the bundle
    // with the size of the base in the bundle and add the prices together
    if (product.bundled_items && product.bundled_items.length > 0) {
      let bundle_base = product.bundled_items.filter(
        (bund) => !bund.categories.some((cat) => cat.slug == "mattresses")
      )
      bundle_base = bundle_base.length ? bundle_base[0] : {}
      let bundle_matt = product.bundled_items.filter((bund) =>
        bund.categories.some((cat) => cat.slug == "mattresses")
      )
      bundle_matt = bundle_matt.length ? bundle_matt[0] : {}

      variationMap = []
      // Match up mattress with base
      if (
        bundle_matt.product_variations &&
        bundle_matt.product_variations.length > 0
      ) {
        for (const matt of bundle_matt.product_variations) {
          const mattSizeAtt = matt.attributes.find((att) => att.name == "Size")
          const mattSize = mattSizeAtt.option
          let bedMatchUp = false

          if (
            bundle_base.product_variations &&
            bundle_base.product_variations.length > 0
          ) {
            for (const base of bundle_base.product_variations) {
              const baseSizeAtt = base.attributes.find(
                (att) => att.name == "Size"
              )
              const baseSize = baseSizeAtt.option

              // Match up Mattress and Base sizes directly
              if (mattSize === baseSize) {
                bedMatchUp = {
                  ...matt,
                  name: `${product.name} - ${mattSize}`,
                  size: mattSize,
                  categories: product.categories,
                  regular_price:
                    parseFloat(matt.regular_price) +
                    parseFloat(base.regular_price),
                  price: parseFloat(matt.price) + parseFloat(base.price),
                  id: [matt.id, base.id],
                  tracking_id: `${matt.id}_${base.id}`,
                  sku: `${matt.sku}_${base.sku}`,
                  backorders_allowed:
                    matt.backorders_allowed && base.backorders_allowed,
                }
              }
              if (bedMatchUp) {
                variationMap.push(bedMatchUp)
                break
              }
            }
            if (!bedMatchUp) {
              for (const base of bundle_base.product_variations) {
                const baseSizeAtt = base.attributes.find(
                  (att) => att.name == "Size"
                )
                const baseSize = baseSizeAtt.option

                // Match up Mattress King/XL with 2x Base Single/XL sizes if no base has been found
                if (
                  (mattSize === "King" && baseSize === "Single") ||
                  (mattSize === "King XL" && baseSize === "Single XL")
                ) {
                  bedMatchUp = {
                    ...matt,
                    name: `${product.name} - ${mattSize}`,
                    size: mattSize,
                    categories: product.categories,
                    regular_price:
                      parseFloat(matt.regular_price) +
                      parseFloat(base.regular_price) * 2,
                    price: parseFloat(matt.price) + parseFloat(base.price) * 2,
                    id: [matt.id, base.id, base.id],
                    tracking_id: `${matt.id}_${base.id}`,
                    sku: `${matt.sku}_${base.sku}`,
                    backorders_allowed:
                      matt.backorders_allowed && base.backorders_allowed,
                  }
                }
                if (bedMatchUp) {
                  variationMap.push(bedMatchUp)
                  break
                }
              }
            }
          }
        }
      }
    } else if (product.categories.some((cat) => cat.slug == "bed-bases")) {
      // When working with bed bases, two Single bases are used as a King base if there is no specific King base
      variationMap = []
      // Nothing to match up, just add some convenience fields
      variationMap = product.product_variations.map((variation) => {
        const sizeAtt = variation.attributes.find((att) => att.name == "Size")
        const size = sizeAtt.option
        return {
          ...variation,
          name: `${product.name} - ${size}`,
          size,
          categories: product.categories,
        }
      })

      // Check if a King base was specified, otherwise build one from two Single bases
      const kingBase = variationMap.find(
        (variation) => slugify(variation.size) === "king"
      )
      if (!kingBase) {
        const singleBase = variationMap.find(
          (variation) => slugify(variation.size) === "single"
        )
        if (singleBase)
          variationMap.push({
            ...singleBase,
            name: `${product.name} - King`,
            regular_price: parseFloat(singleBase.regular_price) * 2,
            price: parseFloat(singleBase.price) * 2,
            id: [singleBase.id, singleBase.id],
            size: "King",
          })
      }

      // Check if a KingXL base was specified, otherwise build one from two SingleXL bases
      const kingXlBase = variationMap.find(
        (variation) => slugify(variation.size) === "king-xl"
      )
      if (!kingXlBase) {
        const singleXlBase = variationMap.find(
          (variation) => slugify(variation.size) === "single-xl"
        )
        if (singleXlBase)
          variationMap.push({
            ...singleXlBase,
            name: `${product.name} - King XL`,
            regular_price: parseFloat(singleXlBase.regular_price) * 2,
            price: parseFloat(singleXlBase.price) * 2,
            id: [singleXlBase.id, singleXlBase.id],
            size: "King XL",
          })
      }
    } else {
      // Nothing to match up, just add some convenience fields
      variationMap = product.product_variations.map((variation) => {
        const sizeAtt = variation.attributes.find((att) => att.name == "Size")
        const size = sizeAtt.option
        return {
          ...variation,
          name: `${product.name} - ${size}`,
          size,
          categories: product.categories,
        }
      })
    }

    // Change the array based on whether a variation or simple product
    let variations = []
    const sizeWidths = []

    if (variationMap.length > 0) {
      variations = variationMap.map((variant) => {
        const sizeName = variant.size
        const sizeNameNotXL = sizeName.replace(" XL", "")
        const sizeWidth = (() => {
          for (
            let sizeName_i = 0;
            sizeName_i < bedWidths.length;
            ++sizeName_i
          ) {
            if (sizeNameNotXL === bedWidths[sizeName_i].name) {
              return `${bedWidths[sizeName_i].width} cm x ${
                sizeName.includes(" XL") ? 200 : 188
              } cm`
            }
          }
          return false
        })()

        if (sizeWidth) {
          sizeWidths.push([sizeName, sizeWidth])
        }

        return {
          label: (
            <Option>
              <div className="size">{sizeName}</div>
              <div className="price"> {format(variant.price)}</div>
            </Option>
          ),
          value: sizeName,
          ...variant,
        }
      })
    } else {
      product.id = product.wordpress_id
      variations = [product]
    }

    // if this page was navigated to from another product page, we retain the
    // state of the bed size/variation chosen and apply it to this page,
    // otherwise defualt to the first option/variation
    const statefulVarientIdx = selectedSize
      ? variations.reduce(
          (acc, { size }, i) => (slugEq(size, selectedSize) ? i : acc),
          0
        )
      : 0

    const [selected, setSelected] = useState({
      main: variations[statefulVarientIdx],
    })
    const [selectedOld, setSelectedOld] = useState(false)

    useEffect(() => {
      if (
        typeof window !== "undefined" &&
        selected &&
        selected.main &&
        selected.main.size
      ) {
        const pathname = window.location.pathname
        const selectedSize = slugify(selected.main.size)
        const newPath = `${pathname}?size=${selectedSize}`
        history.replaceState(null, "", newPath)
      }
    }, [selected])

    useEffect(() => {
      if (
        !selectedOld ||
        (selectedOld.main &&
          selected.main &&
          selectedOld.main.value != selected.main.value)
      ) {
        const trackProd = selectedSize || selectedOld ? selected.main : product
        trackProd.category_names = trackProd.categories.map((cat) => cat.name)
        if (!trackProd.tracking_id) {
          if (trackProd.id) {
            trackProd.tracking_id = trackProd.id
          } else {
            trackProd.tracking_id = trackProd.wordpress_id
          }
        }

        !trackProd.price && (trackProd.price = selected.main.price)
        !trackProd.regular_price &&
          (trackProd.regular_price = selected.main.regular_price)

        gtagSelect(trackProd)
        pixelSelect(trackProd)
        sendRetentionScience({
          viewProductId: trackProd.tracking_id,
        })
      }
      setSelectedOld(selected)
    }, [selected])

    const firstUpdate = useRef(true)
    useLayoutEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false
        return
      }
    })

    const productRating = useMemo(() => {
      if (
        productStock[product.wordpress_id] &&
        productStock[product.wordpress_id].reviews
      ) {
        return parseFloat(
          productStock[product.wordpress_id].review_average_rating
        )
      } else if (product.average_rating) {
        return parseFloat(product.average_rating)
      } else {
        return 0.0
      }
    }, [productStock])

    // Find the bed_bundle_size image corresponding to the selected size, or variation image corresponding to selected size
    const selectedVariationImage = useMemo(() => {
      let bed_bundle_size_images = []
      if (product.type == "bundle") {
        bed_bundle_size_images =
          (product && product.acf && product.acf.bed_bundle_size_images) || []
      }

      if (selected && selected.main && selected.main.value) {
        const returnval = bed_bundle_size_images.find((bund_image) => {
          const skusize =
            bund_image &&
            bund_image.bundle_images &&
            bund_image.bundle_images.product_image_sku &&
            bund_image.bundle_images.product_image_sku.split("_")
          const selectedsizeStr = replaceAll(selected.main.value, " ", "")
          if (skusize && skusize.length > 1 && selectedsizeStr) {
            if (slugify(skusize[1]) == slugify(selectedsizeStr)) {
              return true
            }
          }
        })
        if (
          returnval &&
          returnval.bundle_images &&
          returnval.bundle_images.image &&
          returnval.bundle_images.image.localFile &&
          returnval.bundle_images.image.localFile.childImageSharp
        ) {
          return returnval.bundle_images.image.localFile.childImageSharp.fluid
        } else if (
          selected.main &&
          selected.main.image &&
          selected.main.image.localFile &&
          selected.main.image.localFile.childImageSharp
        ) {
          return selected.main.image.localFile.childImageSharp.fluid
        }
      }
    }, [selected])

    // Promotional (BuyOneGetOneFree) Products
    const promotionalItems = useMemo(() => {
      let free_products = []
      if (queryResponse?.allLocalWpGraphQlBogofs?.nodes?.length > 0) {
        queryResponse.allLocalWpGraphQlBogofs.nodes.forEach(({ bogofRule }) => {
          if (bogofRule.buy_objects_ids) {
            if (
              (selected &&
                selected.main &&
                (bogofRule.buy_objects_ids.includes(product.wordpress_id) ||
                  bogofRule.buy_objects_ids.some((rule_id) =>
                    selected.main.id.length
                      ? selected.main.id.includes(rule_id)
                      : selected.main.id === rule_id
                  ))) ||
              product?.bundled_items?.some((it) =>
                bogofRule.buy_objects_ids.some((id) => it.wordpress_id == id)
              )
            ) {
              free_products.push(...bogofRule.free_product_ids)
            }
          }
        })
      }
      return free_products
    }, [queryResponse, product, selected])

    // Sort variations dropdown according to the sorting array in util.jsx
    variations = variations.sort(
      (a, b) => sizeOrder.indexOf(a.value) - sizeOrder.indexOf(b.value)
    )

    return (
      <div>
        <ProductVariationSelector
          product={product}
          selected={selected}
          variations={variations}
          productStock={productStock}
          productRating={productRating}
          setSelected={setSelected}
          statefulVarientIdx={statefulVarientIdx}
          setGoToRatingsValue={setGoToRatingsValue}
          selectedVariationImage={selectedVariationImage}
          imagePlaceholder={imagePlaceholder}
          promotionalItems={promotionalItems}
        />
        <InfoIcons
          show100Day={product?.acf?.comfort_trial_available == "Yes"}
        />
        <ProductDetails
          location={location}
          product={product}
          productStatus={productStock}
          productRating={productRating}
          selected={selected}
          comfortLevels={comfortLevels}
          goToRatingsValue={goToRatingsValue}
          setGoToRatingsValue={setGoToRatingsValue}
          sizeWidths={sizeWidths}
        />
        <VideoRow product={product} brandVideo={brandVideo} />
        <ProductsYouMayLike
          productName={product.name}
          products={product.related_products}
          selected={selected}
        />
      </div>
    )
  }
)

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

// prettier-ignore
const toKey = str => str.toLowerCase().split(" ").join("-")

const slugEq = (s1, s2) => toKey(s1) === toKey(s2)

const format = (number) =>
  "R " +
  parseFloat(number)
    .toFixed(2) // cents
    .replace(/\d(?=(\d{3})+\.)/g, "$&,") // add comas between thousands

// extract size from attributes array
const getSizeName = (variation) => {
  var size =
    variation.attributes &&
    variation.attributes.find(({ name }) => name === "Size")
  return size && size.option ? size.option : size
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Option = styled.div`
  display: flex;
  justify-content: space-between;
  .price {
    font-weight: 500;
  }
  @media (max-width: ${({ theme }) => theme.breakXTiny}) {
    font-size: 12px;
  }
`

export default ProductSingle

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

const QUERY = graphql`
  {
    allLocalWpGraphQlBogofs {
      nodes {
        bogofRule {
          buy_objects_ids
          enabled
          free_quantity
          free_product_ids {
            name
            slug
            images {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
