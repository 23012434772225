import React, { useEffect, useContext, useState, useMemo, memo } from "react"
import styled from "styled-components"
import BranchContext from "../../context/BranchContext"
import CartContext from "../../context/CartContext"
import ProductContext from "../../context/ProductContext"
import { Container } from "./Layout"
import Crumbs from "./Crumbs"
import Slider from "./Slider"
import Filter from "./Filter"
import Details from "./Details"
import CreditOptions from "./CreditOptions"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ListCheck from "../../zzz/atoms/icons/files/list-check.jsx"
import ArrowRight from "../../zzz/atoms/icons/files/arrow-right.jsx"
import Delivery from "../../zzz/atoms/icons/files/delivery.jsx"
import Info from "../../zzz/atoms/icons/files/info.jsx"
import { path, shippingClassGet, lookupShippingClass } from "../../lib/util"
import { Responsive, windowSizes } from "../../utils/responsive"
import Tooltip from "./Tooltip"
import { graphql, useStaticQuery } from "gatsby"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { addToCompare } from "../Products/CompareProducts"
import Button from "./Button"
import { slugify } from "voca"

const maxSelectableStock = 99

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const ProductVariationSelector = ({
  product,
  selected,
  setSelected,
  variations,
  productStock,
  productRating,
  statefulVarientIdx,
  setGoToRatingsValue,
  selectedVariationImage,
  imagePlaceholder,
  promotionalItems,
}) => {
  const { selectedBranch } = useContext(BranchContext)
  const { addToCart, setCartDrawerOpen, setCompareDrawerOpen } = useContext(
    CartContext
  )
  const { feverTreeInstalments, setCurrentProduct } = useContext(ProductContext)

  const [counterFocus, setCounterFocus] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [addToCartLoading, setAddToCartLoading] = useState(false)
  const [apiError, setApiError] = useState("")
  const [maxStock, setMaxStock] = useState(maxSelectableStock)
  const [stockUpdateData, setStockUpdateData] = useState([])
  const [shippingClass, setShippingClass] = useState(
    selected.main.shipping_class || product.shipping_class
  )
  const [productStockFetched, setProductStockFetched] = useState(false)

  const queryResponse = useStaticQuery(QUERY)
  const deliveryTsCs = getTsCs(queryResponse)

  const productType = useMemo(() => {
    if (product.bundled_items && product.bundled_items.length) {
      return "bundle"
    } else if (product.product_variations.length > 0) {
      return "variable"
    } else {
      return "simple"
    }
  }, [product])

  useEffect(() => {
    if (
      !productStock ||
      (Array.isArray(productStock) && !productStock.length) ||
      Object.keys(productStock).length === 0
    ) {
      setProductStockFetched(false)
    } else {
      // Prepare an array with all the possible product ID's to check stock with
      let stockIdArray = []
      if (selected.main && selected.main.id) {
        if (Array.isArray(selected.main.id)) {
          stockIdArray.push(...selected.main.id)
        } else {
          stockIdArray.push(selected.main.id)
        }
      } else {
        stockIdArray.push(product.wordpress_id)
      }
      // Iterate and use the lowest stock item as the max
      const stockUpdateData_temp = []
      let maxStock_temp = maxSelectableStock
      let shippingClass_temp =
        selected.main.shipping_class || product.shipping_class
      let locationStockCounter = 0
      let locationEffectiveStock = null
      let locationSoh = null
      for (const stockId of stockIdArray) {
        if (productStock[stockId] && productStock[stockId].manage_stock) {
          stockUpdateData_temp.push(productStock[stockId])
          if (productStock[stockId].stock_quantity < maxStock_temp) {
            maxStock_temp = productStock[stockId].stock_quantity
          }

          const stockByLoc = productStock[stockId].stock_by_location
          if (selectedBranch && stockByLoc && selectedBranch in stockByLoc) {
            const locStock = stockByLoc[selectedBranch]
            if (locStock && "soh" in locStock && "effective" in locStock) {
              if (locationStockCounter === 0) {
                locationEffectiveStock = locStock.effective
                locationSoh = locStock.soh
              } else {
                locationEffectiveStock = Math.min(
                  locStock.effective,
                  locationEffectiveStock
                )
                locationSoh = Math.min(locStock.soh, locationSoh)
              }
              locationStockCounter++
            }
          }
        }
      }

      if (locationStockCounter == Object.keys(stockIdArray).length) {
        maxStock_temp = locationEffectiveStock
        shippingClass_temp = lookupShippingClass(
          locationSoh,
          locationEffectiveStock
        )
      }

      setStockUpdateData(stockUpdateData_temp)
      setProductStockFetched(true)
      setMaxStock(maxStock_temp)
      setShippingClass(shippingClass_temp)
    }
  }, [selected, productStock, selectedBranch])

  // const promotional = getBogofs(queryResponse, product.wordpress_id)
  const images = getFluidImagesFromProduct(product)
  let imagesInSlider = useMemo(
    () =>
      selectedVariationImage ? [selectedVariationImage, ...images] : images,
    [selectedVariationImage, images]
  )

  const handleAddToCart = () => {
    setAddToCartLoading(true)
    let addAllToCart = []
    // Product Bundles require array of variation_id and bundle_id
    if (product.bundled_items && product.bundled_items.length > 0) {
      const variation_array = []

      Object.values(selected).forEach((item) => {
        if (item.id && item.id.length > 0) {
          variation_array.push(...item.id)
        } else {
          variation_array.push(item.id)
        }
      })
      const bundle_data = {
        variation_id: variation_array,
        bundle_id: product.wordpress_id,
        quantity,
      }
      addAllToCart.push(addToCart(bundle_data))
      // Variation require variation_id or array of variation_id
    } else {
      const id_name =
        product.product_variations && product.product_variations.length > 0
          ? "variation_id"
          : "product_id"
      if (id_name === "variation_id") {
        // Add as array of varation ID's (fast!)
        const allIdArrays = Object.values(selected).map((item) => item.id)
        const mergedIds = [].concat.apply([], allIdArrays)
        addAllToCart.push(addToCart({ [id_name]: mergedIds, quantity }))
      } else {
        // Add each item individually (slow!)
        Object.values(selected).forEach((item) => {
          if (item.id && item.id.length > 0) {
            addAllToCart.push(
              ...item.id.map((id) => addToCart({ [id_name]: id, quantity }))
            )
          } else {
            addAllToCart.push(addToCart({ [id_name]: item.id, quantity }))
          }
        })
      }
    }
    // Normal product require product_id
    Promise.all(addAllToCart)
      .then(() => {
        setApiError("")
        setAddToCartLoading(false)
        setCartDrawerOpen(true)
      })
      .catch(async (error) => {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        setApiError(errorString)
        setAddToCartLoading(false)
      })
  }

  const fewStock = maxStock < 10 && maxStock > 0
  const noStock = maxStock <= 0
  const final_price = Object.values(selected).reduce((acc, cur) => {
    return (acc += parseFloat(cur.price))
  }, 0)
  const final_regular_price = Object.values(selected).reduce((acc, cur) => {
    return (acc += parseFloat(cur.regular_price))
  }, 0)

  // Check and build the link to the mattress if this is a bed
  const mattressLink = useMemo(() => {
    if (
      product.bundled_items &&
      product.bundled_items.length &&
      product.categories.some(({ slug }) => slug == "beds")
    ) {
      const matchingMattress = product.bundled_items.find((pr) =>
        pr.categories.some(({ slug }) => slug == "mattresses")
      )
      const selectedsize =
        selected.main &&
        selected.main.value &&
        `?size=${slugify(selected.main.value)}`
      return (
        matchingMattress.slug &&
        `/product/${matchingMattress.slug}/${selectedsize}`
      )
    }
    return false
  }, [product, selected])
  // Check and build the link to the mattress if this is a bed
  const bedLink = useMemo(() => {
    if (
      product.bundled_by &&
      product.bundled_by.length &&
      product.categories.some(({ slug }) => slug == "mattresses")
    ) {
      const matchingBed = product.bundled_by[0]
      const selectedsize =
        selected.main &&
        selected.main.value &&
        `?size=${slugify(selected.main.value)}`
      return matchingBed.slug && `/product/${matchingBed.slug}/${selectedsize}`
    }
    return false
  }, [product, selected])

  // Convenience check if compare button should be visible
  const canBeCompared = useMemo(() => {
    return (
      product &&
      product.categories &&
      (product.categories.some(({ name }) => name == "Mattresses") ||
        product.categories.some(({ name }) => name == "Beds"))
    )
  }, [product, productType])

  // Inclusive cutoff. If the price is equal to or larger then credit is available
  const fevertree_instalment_cutoff = 499
  // We need the rounded price for the object key
  const rounded_price = Math.round(final_price)
  let fevertree_instalment_price = false
  if (rounded_price >= fevertree_instalment_cutoff) {
    if (feverTreeInstalments && feverTreeInstalments.length) {
      // The instalment price can only be set if the rounded price is
      // larger than or equal to the cutoff and the price is in the instalment map
      fevertree_instalment_price = feverTreeInstalments.find(
        (inst) => inst.price == rounded_price
      )
    }
  }

  const backorders_allowed = selected.main.backorders_allowed

  let badgeText = "AVAILABLE"
  let badgeColor = "secondary"
  let addToCartButtonText = "ADD TO CART"
  if (noStock && !backorders_allowed) {
    badgeColor = "tertiary"
    if (productType == "simple") {
      badgeText = "OUT OF STOCK"
      addToCartButtonText = "OUT OF STOCK"
    } else {
      badgeText = "SELECTED OPTION IS OUT OF STOCK"
      addToCartButtonText = "OPTION IS OUT OF STOCK"
    }
  } else if (shippingClass) {
    badgeText = shippingClassGet(shippingClass, "text")
    if (shippingClass === "fast-track-shipping") {
      badgeColor = "teal"
    } else {
      badgeColor = "primary"
    }
  } else if (fewStock) {
    badgeText = "LOW STOCK"
    badgeColor = "secondary"
  }

  // this useEffect needs to be defined after imagesInSlider
  useEffect(() => {
    setCurrentProduct({
      name: product.name,
      image: product?.images?.[0],
      // image: imagesInSlider,
      stockUpdateData,
    })
  }, [stockUpdateData])

  const product_sku = selected.main.sku || product.sku
  return (
    <Container grey grid="3fr 2fr" gridMd="1fr" bottompad="30px">
      <Badge color={badgeColor}>{badgeText}</Badge>
      <Left>
        <Crumbs product={product} />
        <Responsive maxWidth={windowSizes.mobile}>
          <Details
            title={product.name}
            sku={product_sku}
            rating={productRating}
            salePrice={final_price}
            oldPrice={final_regular_price}
            setGoToRatingsValue={setGoToRatingsValue}
            productStockFetched={productStockFetched}
            mobile
          />
        </Responsive>

        <Space />
        <Sliders cols={1}>
          <Slider
            rawImages={imagesInSlider}
            imagePlaceholder={imagePlaceholder}
            productName={product.name}
          />
          <Plus className="plus">Plus</Plus>
        </Sliders>
        {promotionalItems.length > 0 &&
          promotionalItems.map((pr, idx) => (
            <PromotionalItem key={idx}>
              <img src={pr?.images?.[0]?.localFile?.publicURL} />
              <div className="margins">
                <div className="textcontainer">
                  <div className="text">FREE with this purchase!</div>
                </div>
                <Link className="product-name" to={`/product/${pr.slug}/`}>
                  {pr.name}
                </Link>
                <div className="small-print">
                  {"Subject to stock availability"}
                </div>
              </div>
            </PromotionalItem>
          ))}
        <AlternativeProductLinks>
          {mattressLink && (
            <div>
              Already have a base? <Link to={mattressLink}>View mattress</Link>
            </div>
          )}
          {bedLink && (
            <div>
              Need a base? <Link to={bedLink}>View combo</Link>
            </div>
          )}
        </AlternativeProductLinks>
        <Conditions>
          <div>
            <SvgIcon SvgComponent={Delivery} hue="tusk" shade="080" />
            <div
              style={{
                color: shippingClassGet(shippingClass, "color"),
              }}
            >
              {`Delivery Estimate: ${shippingClassGet(
                shippingClass,
                "delay"
              )} business days (main city hubs)`}
            </div>
            <Tooltip
              text={deliveryTsCs}
              background={"#1a293c"}
              minWidth="200px"
            >
              <Circle>
                <SvgIcon hue="white" shade="000" SvgComponent={Info} />
              </Circle>
            </Tooltip>
          </div>
          <span className="right">*E&OE*</span>
        </Conditions>
      </Left>
      <Right>
        <Responsive minWidth={windowSizes.mobile}>
          <Details
            title={product.name}
            sku={product_sku}
            rating={productRating}
            salePrice={final_price}
            oldPrice={final_regular_price}
            setGoToRatingsValue={setGoToRatingsValue}
            productStockFetched={productStockFetched}
          />
          <Space />
        </Responsive>
        <Filter
          title={productType == "bundle" ? "Bed Size" : "Mattress Size"}
          selectorTag="main"
          options={variations}
          setSelected={setSelected}
          defaultIndex={statefulVarientIdx}
          quantity={quantity}
          onQuantityChange={setQuantity}
          onCounterFocusChange={setCounterFocus}
          counterFocus={counterFocus}
          maxStock={maxStock}
          includeSelector={productType == "simple" ? false : true}
          quantityOnly={productType == "simple"}
          disableDropdown={!productStockFetched}
        />
        <CreditOptions
          feverTree={{
            amount:
              fevertree_instalment_price &&
              fevertree_instalment_price.instalment,
          }}
          payflex={{ amount: final_price }}
        />
        <Space />
        {apiError && (
          <>
            <ScaryNote dangerouslySetInnerHTML={{ __html: apiError }} />
            <Space />
          </>
        )}
        <Grid>
          <Button
            SvgComponent={ArrowRight}
            label={addToCartButtonText}
            onClick={handleAddToCart}
            loading={addToCartLoading}
            // disabled={!productStockFetched}
          />
          {canBeCompared && (
            <Button
              label="add to compare"
              SvgComponent={ListCheck}
              color="#223247"
              onClick={() => {
                addToCompare(product.wordpress_id)
                setCompareDrawerOpen(true)
              }}
            />
          )}
        </Grid>
      </Right>
    </Container>
  )
}

const PromotionalItem = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 140px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  box-shadow: rgba(119, 132, 142, 0.19) 0px 10px 15px -7px;
  background: white;
  img {
    height: 100%;
    margin: 10px 20px;
  }
  .margins {
    span,
    div,
    a {
      margin: 5px 0;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      line-height: 1.2;
      margin: 5px;
    }
  }
  .text {
    background: rgb(96, 196, 232);
    color: white;
    padding: 2px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 18px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: none;
      padding: 5px;
      margin: 2px;
      font-size: 14px;
    }
  }
  .textcontainer {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      background: rgb(96, 196, 232);
      border-radius: 4px;
    }
  }
  .product-name {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 14px;
    }
  }
  .small-print {
    font-weight: 300;
    font-size: 12px;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      font-size: 10px;
    }
  }
`

const Plus = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background: #b8daea;
  color: #235e79;
  border-radius: 4px;
  display: none;
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const getFluidImagesFromProduct = (product) => {
  const { images: imgs } = product
  return imgs.map(getFluid)
}

// extract relavant data from query to avoid bloat in component
const getTsCs = ({
  localWpGraphQlTmwGeneralSettings: {
    tmw_settings: { deliveryTsCs },
  },
}) => deliveryTsCs

// takes an object and returns value at path or undefined
const getFluid = (obj) => path(["localFile", "childImageSharp", "fluid"], obj)

const colors = {
  primary: { background: "#FCDBA2", border: "#DA8D08", text: "#DA8D08;" },
  secondary: { background: "green", border: "purple", text: "white" },
  tertiary: { background: "#efb0b3", border: "#d91a1f", text: "#d91a1f" },
  teal: { background: "#0988bb", border: "#0988bb", text: "white" },
}

const getColor = (type) => ({ color }) => colors[color][type]

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    display: none;
  }
  h5 {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    width: 90px;

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      width: auto;
    }
  }

  div {
    padding: 10px 20px;
    background: #b8daea;
    color: #235e79;
    border-radius: 4px;
  }
`

const Circle = styled.div`
  border-radius: 200px;
  background: #9ba5ae;
  transform: scale(0.8);
`

const NiceNote = styled.div`
  font-size: 18px;
  text-align: center;
  color: #0988bb;
  text-transform: uppercase;
`

const ScaryNote = styled.p`
  text-align: center;
  color: #fd3237;
`

const Sliders = styled.div`
  position: relative;

  ${({ cols }) =>
    cols === 2 &&
    "display:grid; grid-template-columns:repeat(2,1fr);grid-gap:20px;"}

  ${({ cols }) =>
    cols === 2 &&
    ".wrapper-slide{height:375px;} .container-slide{height:375px;}"}

		@media (max-width: ${({ theme }) => theme.breakSmall}) {
    ${({ cols }) =>
      cols === 2 &&
      ".wrapper-slide{height:275px;} .container-slide{height:275px;} grid-gap: 5px;"}

    ${({ cols }) => cols === 2 && ".plus{display:block;}"}
  }
`

const Badge = styled.div`
  border-radius: 2px;
  text-transform: uppercase;
  background: ${getColor("background")};
  /* border: 1px solid ${getColor("border")}; */
  color: ${getColor("text")};
  padding: 0px 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    top: -20px;
  }
`

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  button {
    margin-bottom: 10px;
    justify-content: space-between;
    > div.text {
      width: 100%;
    }
  }

  .compare {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    transition: 0.2s all ease-in-out;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      padding: 10px;
      border: 1px solid #b3b3b3;
      border-radius: 3px;
      margin: 10px 0;
    }
    &:hover {
      /* transform: translateY(-3px); */
    }
  }
`

const AlternativeProductLinks = styled.div`
  color: #3e4b5c;
  margin: 10px 0 10px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: start;
  a {
    color: #0988bb;
    font-weight: bold;
  }
`
const Conditions = styled.div`
  color: #3e4b5c;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    > span.icon:first-child {
      margin-right: 10px;
    }
    > div:last-child {
      margin-left: 10px;
    }
  }

  .right {
    color: #9ba5ae;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    justify-content: space-between;
  }
`

const Left = styled.div``
const Right = styled.div``

const Space = styled.div`
  height: 20px;
`

// ======================
// 	👨‍💻👨‍💻 QUERY 👨‍💻👨‍💻
// ======================

const QUERY = graphql`
  {
    localWpGraphQlTmwGeneralSettings {
      tmw_settings {
        deliveryTsCs
      }
    }
  }
`

export default memo(ProductVariationSelector)
