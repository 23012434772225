import React, { memo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const PayjustnowWidget = memo(() => {
  const {
    payjustnowImage: {
      childImageSharp: { fluid: payjustnow },
    },
  } = useStaticQuery(PAYJUSTNOW_WIDGET_QUERY)

  return (
    <WidgetBox>
      <Img
        fluid={payjustnow}
        style={{ height: "64px", width: "160px" }}
        imgStyle={{ height: "64px", width: "160px", objectFit: "contain" }}
        objectFit="contain"
        objectPosition="50% 50%"
      />
      <InstalmentInfo>
        <TextBox>PayJustNow offers you 3 equal interest-free payments</TextBox>
        <TextBox>Choose PayJustNow during checkout</TextBox>
      </InstalmentInfo>
    </WidgetBox>
  )
})

const TextBox = styled.div``

const WidgetBox = styled.div`
  height: 75px;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    height: fit-content;
  }
  @media (max-width: ${({ theme }) => theme.breakXTiny}) {
    height: 100px;
  }
`
const InstalmentInfo = styled.div`
  text-align: center;
  flex: 1 1 auto;
  font-size: 12px;
  span {
    font-weight: bold;
  }
`

export default PayjustnowWidget

const PAYJUSTNOW_WIDGET_QUERY = graphql`
  {
    payjustnowImage: file(relativePath: { eq: "payjustnow.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
