import React, { memo, useMemo, useState } from "react"
import styled from "styled-components"
import ReactPlayer from "react-player/youtube"
import BackgroundImage from "gatsby-background-image"
import { keyframes } from "styled-components"
import Modal from "react-modal"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const VideoRow = memo(({ product, brandVideo }) => {
  const brandContentRaw = getBrandVideo(brandVideo)
  const brandContent = useMemo(() => flatten(brandContentRaw), [
    brandContentRaw,
  ])
  const productBrand = useMemo(
    () => product.categories.find(({ slug }) => brandContent[slug]),
    [product]
  )

  const [open, setOpen] = useState(false)

  const key = productBrand && productBrand.slug ? productBrand.slug : "fallback"
  // Don't render component if no asscoiated video exists for category
  if (key === "fallback") return null
  const content = brandContent[key]
  const background = content.fluid

  return (
    <Container fluid={background}>
      <Title>{content.title}</Title>
      <Body>{content.description}</Body>
      <Play onClick={() => setOpen(true)}>
        <Circle className="circle">
          <Triangle />
        </Circle>
        <span>Watch the video</span>
      </Play>

      <Modal
        closeTimeoutMS={400}
        isOpen={open}
        style={customStyles}
        onRequestClose={() => setOpen(false)}
        // className="my-modal"
      >
        <ReactPlayer url={content.videoUrl} width="100%" />
      </Modal>
    </Container>
  )
})

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

// converts acf array to obj for easier use
const flatten = (brandVideo) =>
  brandVideo.reduce(
    (acc, { productCat, title, videoUrl, description, backgoundImage }) => ({
      ...acc,
      [productCat]: {
        productCat,
        title,
        description,
        videoUrl,
        fluid: backgoundImage.imageFile.localFile.childImageSharp.fluid,
      },
    }),
    {}
  )

const getBrandVideo = (brandVideo) => {
  return (
    brandVideo &&
    brandVideo.product_videos &&
    brandVideo.product_videos.brandVideo
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================

// Modal styles
const customStyles = {
  overlay: {
    background: "#162232de",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const pulse = keyframes`
{
  0% {
    box-shadow: 0 0 0 0 rgba(237,28,36, 0.4);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}
`

const Play = styled.div`
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  span {
    transition: 0.2s all ease-in-out;
  }
  &:hover {
    .circle {
      animation: ${pulse} 2s infinite;
    }
    span {
      transform: translateX(3px);
    }
  }
`

const Circle = styled.div`
  background-color: #ed1c24;
  border-radius: 200px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 7px;
  animation: none;
  margin-right: 20px;
  /* &:hover {
		
	} */
`
const Triangle = styled.div`
  position: relative;
  background-color: white;
  text-align: left;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: inherit;
  }
  &,
  &:before,
  &:after {
    transition: 0.2s all ease-in-out;
    width: 10px;
    height: 10px;
    border-top-right-radius: 30%;
  }

  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);

  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`

const Container = styled(BackgroundImage)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 100px 40px;

  /* .my-modal{

	} */
`

const Title = styled.h4`
  background-color: #ed1c24;
  color: white;
  padding: 8px 20px;
  font-size: 20px;
  border-radius: 2px;
`
const Body = styled.h4`
  color: #ffffff;
  font-size: 31px;
  font-weight: 300;
  max-width: 500px;
  text-align: center;
  margin: 60px 0;
`

export default VideoRow
