import React, { memo } from "react"
import styled from "styled-components"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronRight from "../../zzz/atoms/icons/files/chevron-right.jsx"
import { buildCrumbs } from "../../lib/util"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Crumbs = memo(({ product }) => {
  const crumbs = buildCrumbs(product)

  return (
    <Container>
      {crumbs.map(({ name, link }, i) => (
        <Item key={i}>
          <Link to={link}>{name}</Link>
          {i < crumbs.length - 1 && (
            <SvgIcon SvgComponent={ChevronRight} hue="tusk" shade="100" />
          )}
        </Item>
      ))}
    </Container>
  )
})

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
const Container = styled.div`
  display: flex;
  align-items: center;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #77848e;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 12px;
  }
  a {
    color: #77848e;
    transition: 0.2s all ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
  > span.icon {
    margin: 0 10px;
  }
`

export default Crumbs
