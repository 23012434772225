import React from "react"

const IconColorReturns = () => (
  <div className="icon-color">
    <svg width="66" height="51" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-17 -25)" fill="none" fillRule="evenodd">
        <path
          d="M20.151 64.694c1.465-6.807 4.878-11.1 10.24-12.882 5.363-1.782 13.241-3.469 23.634-5.06l-33.874-.058v18zM38.324 28.151c-4.52.18-7.584 1.17-9.19 2.969-1.608 1.798-4.385 5.425-8.333 10.88L25 28.151h13.324zM46 28.151c4.52.18 7.583 1.17 9.19 2.969 1.607 1.798 4.392 5.425 8.354 10.88l-4.22-13.849H46z"
          fill="#FCD9DA"
        />
        <path
          d="M67.262 43.474a14.424 14.424 0 00-3.388-.24l-4.67-14.01a1.58 1.58 0 00-1.497-1.073H43.784v16.543h14.425a14.354 14.354 0 00-4.203 3.151H20.151V70.69a1.58 1.58 0 001.576 1.576H62.69c.413 0 .79-.161 1.07-.423a14.263 14.263 0 008.976-2.543l.505 2.336c0 2.61-6.366 3.78-8.975 3.78h-42.54A4.728 4.728 0 0117 70.69V44.674c0-.512.089-1.014.246-1.497L22.23 28.23a4.714 4.714 0 014.48-3.23h30.998c2.039 0 3.84 1.3 4.48 3.23l4.983 14.947c.034.099.065.197.092.297zm-47.111 1.2l20.482.02V28.151H26.709c-.68 0-1.28.433-1.497 1.073L20.23 44.172a1.78 1.78 0 00-.079.502z"
          fill="#223247"
        />
        <circle fillOpacity=".169" fill="#ED1C24" cx="65" cy="58" r="16" />
        <path
          d="M64.552 39.781c9.809 0 17.76 7.952 17.76 17.76 0 9.81-7.951 17.76-17.76 17.76-9.809 0-17.76-7.95-17.76-17.76 0-9.808 7.951-17.76 17.76-17.76zm0 3.438c-7.917 0-14.323 6.407-14.323 14.323s6.407 14.322 14.323 14.322c7.917 0 14.323-6.407 14.323-14.322 0-7.917-6.407-14.323-14.323-14.323z"
          fill="#223247"
          fillRule="nonzero"
        />
        <path
          d="M74.593 52.548a.86.86 0 01-.005 1.215l-12.36 12.26a.86.86 0 01-1.215-.004l-6.502-6.554a.86.86 0 01.005-1.215l1.627-1.614a.86.86 0 011.216.005l4.282 4.316 10.122-10.041a.86.86 0 011.216.005l1.614 1.627z"
          fill="#ED1C24"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
)

export default IconColorReturns
