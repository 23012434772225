import React, { memo, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import SvgIcon from "../../zzz/atoms/icons/svg-icon"
import ChevronLeft from "../../zzz/atoms/icons/files/chevron-left.jsx"
import ChevronRight from "../../zzz/atoms/icons/files/chevron-right.jsx"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const Slider = ({ rawImages, imagePlaceholder, productName }) => {
  const fallback =
    imagePlaceholder &&
    imagePlaceholder.childImageSharp &&
    imagePlaceholder.childImageSharp.fluid
  const [active, setActive] = React.useState(0)

  const filteredRawImages =
    rawImages && rawImages.length && rawImages.filter((img) => img)

  let images =
    filteredRawImages && filteredRawImages.length > 0
      ? filteredRawImages
      : [fallback]

  images = removeDups(images)

  // Hard limit to 6 images otherwise the gallery becomes to wide.
  // We can fix this when it becomes necessary
  images = images.slice(0, 6)

  // images.filter((im, idx) => images.find(ims => ims.src == im.src))
  const next = () => setActive(active === images.length - 1 ? 0 : active + 1)
  const prev = () => setActive(active === 0 ? images.length - 1 : active - 1)
  const x = React.useRef(0)

  const registerXPos = ({ changedTouches }) =>
    (x.current = changedTouches[0].clientX)

  const changeSlide = ({ changedTouches }) => {
    var newX = changedTouches[0].clientX
    if (x.current < newX) prev()
    else next()
  }

  return (
    <>
      <Container className="container-slide">
        {images.length > 1 && (
          <Nav left onClick={prev}>
            <SvgIcon SvgComponent={ChevronLeft} size="lg" />
          </Nav>
        )}
        <SlideWrap
          length={images.length}
          active={active}
          onTouchStart={registerXPos}
          onTouchEnd={changeSlide}
          className="wrapper-slide"
        >
          {images.map((image, i) => (
            <SlideImg
              fadeIn={false}
              length={images.length}
              imgStyle={{ objectFit: "contain" }}
              fluid={image || fallback}
              alt={productName}
              key={i}
            />
          ))}
        </SlideWrap>
        {images.length > 1 && (
          <Nav right onClick={next}>
            <SvgIcon SvgComponent={ChevronRight} size="lg" />
          </Nav>
        )}
      </Container>
      <Gallery
        setActive={setActive}
        items={images}
        active={active}
        productName={productName}
      />
    </>
  )
}

// decide if the memoized component should render
const shouldSkipRender = (prevProps, nextProps) => {
  if (
    prevProps &&
    prevProps.imagePlaceholder &&
    prevProps.rawImages &&
    nextProps &&
    nextProps.imagePlaceholder &&
    nextProps.rawImages
  ) {
    if (prevProps.rawImages.length === nextProps.rawImages.length) {
      for (let i = 0; i <= nextProps.rawImages.length; i++) {
        if (
          nextProps.rawImages[i] &&
          prevProps.rawImages[i] &&
          nextProps.rawImages[i].src != prevProps.rawImages[i].src
        ) {
          return false
        }
      }
      return true
    }
  }
  return false
}

const removeDups = (images) => {
  let unique = []
  return images.filter(function (i) {
    if (!unique.includes(i.src)) {
      unique.push(i.src)
      return true
    } else {
      return false
    }
  })
}

// ====================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ====================
export const Gallery = ({ items, active, setActive, className }) => {
  return (
    <GalleryWrap className={className}>
      {items.map((item, i) => (
        <div key={i} onClick={() => setActive(i)}>
          <GalleryImage
            length={items.length}
            imgStyle={{ objectFit: "contain" }}
            objectPosition="50% 50%"
            fluid={item}
            active={active === i}
          />
        </div>
      ))}
    </GalleryWrap>
  )
}

const GalleryImage = styled(Img)`
  width: 80px;
  height: 80px;
  margin: 5px 3px;
  border-style: solid;
  border-color: ${({ active }) => (active ? "#0988bb" : "#e6e6e6")};
  border-width: 1px;
  border-radius: 6px;
  flex-shrink: 0;
  cursor: pointer;
  @media (max-width: 600px) {
    display: none;
  }
`
const GalleryWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const SlideImg = styled(Img)`
  width: ${({ length }) => `${100 / length}%`};
  height: 470px;
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 300px;
  }
`

const SlideWrap = styled.div`
  height: 470px;
  width: ${({ length }) => length * 100 + "%"};
  display: flex;
  overflow-x: auto;
  transition: 0.2s all ease-in-out;
  transform: translateX(
    ${({ length, active }) => (-100 / length) * active + "%"}
  );

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 300px;
  }
`

const Container = styled.div`
  height: 400px;
  display: grid;
  align-items: center;
  transition: 0.2s all ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    height: 300px;
  }

  overflow: hidden;
  background: white;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  border-radius: 4px;
  border: 1px #e6e6e6 solid;
  position: relative;
`
const Nav = styled.div`
  cursor: pointer;
  position: absolute;
  top: 45%;
  transition: 0.2s all ease-in-out;
  ${({ left }) => (left ? "left:5px;" : "right:5px;")}
  &:hover {
    transform: translateX(${({ left }) => (left ? "-3px" : "3px")});
  }
  z-index: 5;
`

export default memo(Slider, shouldSkipRender)
